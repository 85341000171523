import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Arnotts = ({ location }) => {
    const show = location.pathname.startsWith('/arnotts');
    //if (show) document.getElementById('ant').scrollIntoView();
    //
    // /arnotts/home-sale/
    // console.info(location);

    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/arnotts" pathname={location.pathname}>
                        Arnotts
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/home-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Home Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/electrical-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Electrical Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/furniture-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Furniture Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/mens-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Mens Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/kids-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Kids Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/womens-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Womens Sale
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/arnotts/beauty-sale"
                        pathname={location.pathname}
                    >
                        Arnotts Beauty Sale
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const ArnottsLocation = (props) => (
    <Location>
        {(locationProps) => <Arnotts {...locationProps} {...props} />}
    </Location>
);

export default ArnottsLocation;
