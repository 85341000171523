import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from './MenuItemStyled';

const FirstLevel = ({ location }) => {
    //console.info(location);
    return (
        <div className="font-sans flex snap-x overflow-x-auto font-medium text-slate-700">
            <MenuItemStyled to="/ikea" pathname={location.pathname}>
                Ikea
            </MenuItemStyled>
            <MenuItemStyled to="/jysk" pathname={location.pathname}>
                Jysk
            </MenuItemStyled>
            <MenuItemStyled to="/currys" pathname={location.pathname}>
                Currys
            </MenuItemStyled>
            <MenuItemStyled to="/arnotts" pathname={location.pathname}>
                Arnotts
            </MenuItemStyled>
            <MenuItemStyled to="/regatta" pathname={location.pathname}>
                Regatta
            </MenuItemStyled>
            <MenuItemStyled to="/ryanair" pathname={location.pathname}>
                Ryanair
            </MenuItemStyled>
        </div>
    );
};

const FirstLevelLocation = (props) => (
    <Location>
        {(locationProps) => <FirstLevel {...locationProps} {...props} />}
    </Location>
);

export default FirstLevelLocation;
