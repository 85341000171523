import React from 'react';

import Arnotts from './Links/Arnotts';
import Currys from './Links/Currys';
import Daft from './Links/Daft';
import Ikea from './Links/Ikea';
import Jysk from './Links/Jysk';
import Regatta from './Links/Regatta';
import Ryanair from './Links/Ryanair';

const SecondLevel = (props) => {
    return (
        <>
            <div className="font-sans flex snap-x overflow-x-auto font-medium text-slate-700">
                <Ikea />
                <Jysk />
                <Arnotts />
                <Currys />
                <Regatta />
                <Ryanair />
            </div>
        </>
    );
};

export default SecondLevel;
