import * as React from 'react';

import FirstLevel from './FirstLevel';
import './Main.Module.css';
import SecondLevel from './SecondLevel';

const Header = () => {
    return (
        <header>
            <div className="mb-2">
                <div className="float-left p-3">
                    <a aria-current="page" href="\">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Empregos TI</title>
                            <path
                                d="M13 23.959C19.16 23.45 24 18.29 24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 6.29 4.84 11.45 11 11.959v-8.56S10.8 12 8 12c0 0 1.5.8 1.9 3.7C4.2 16.9 4 9.1 4 9.1s6.1-.9 7.3 3C12.1 6 21 7 21 7s0 9.6-7 8.9c0-2 2-5 2-5-3 1-3 4.9-3 4.9v8.16zM12.041 24h-.082.082z"
                                fill="currentColor"
                            />
                        </svg>
                    </a>
                </div>
                <div>
                    <FirstLevel />
                    <div className="ml-12">
                        <SecondLevel />
                    </div>
                </div>
            </div>

            {/* <a
        className="header-module--searchButton--2Ld1L"
        href="https://shopify-demo.gatsbyjs.com/search"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Search</title>
          <circle cx="11" cy="11" r="7" stroke="currentColor" />
          <path stroke="currentColor" d="M15.914 16l4.596 4.596" />
        </svg>
      </a>
      <a
        aria-label="Shopping Cart with 0 items"
        className="cart-button-module--cartButton--1yKBv"
        href="https://shopify-demo.gatsbyjs.com/cart"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Cart</title>
          <path
            d="M3.077 5.05A2 2 0 015.076 3h13.848a2 2 0 012 2.05l-.35 14a2 2 0 01-2 1.95H5.426a2 2 0 01-2-1.95l-.35-14z"
            stroke="currentColor"
          />
          <path
            d="M16 6c0 2.761-1.79 5-4 5S8 8.761 8 6"
            stroke="currentColor"
          />
        </svg>
      </a> */}
        </header>
    );
};

export default Header;
