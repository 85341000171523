import { Link } from 'gatsby';
import styled from 'styled-components';

const MenuItemStyled = styled(Link).attrs((props) => ({
    className: `snap-center shrink-0 px-3 py-2 hover:underline ${
        props.to === props.pathname ? 'bg-slate-300' : ''
    }`,
}))``;

export default MenuItemStyled;
