import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Ryanair = ({ location }) => {
    const show = location.pathname.startsWith('/ryanair');
    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/ryanair" pathname={location.pathname}>
                        Ryanair flights
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ryanair/dublin"
                        pathname={location.pathname}
                    >
                        Flights from Dublin
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ryanair/cork"
                        pathname={location.pathname}
                    >
                        Flights from Cork
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ryanair/shannon"
                        pathname={location.pathname}
                    >
                        Flights from Shannon
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ryanair/kerry"
                        pathname={location.pathname}
                    >
                        Flights from Kerry
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ryanair/knock"
                        pathname={location.pathname}
                    >
                        Flights from Knock
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const RyanairLocation = (props) => (
    <Location>
        {(locationProps) => <Ryanair {...locationProps} {...props} />}
    </Location>
);

export default RyanairLocation;
