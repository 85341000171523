import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Currys = ({ location }) => {
    const show = location.pathname.startsWith('/currys');
    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/currys" pathname={location.pathname}>
                        Currys
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const CurrysLocation = (props) => (
    <Location>
        {(locationProps) => <Currys {...locationProps} {...props} />}
    </Location>
);

export default CurrysLocation;
