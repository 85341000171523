import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Jysk = ({ location }) => {
    const show = location.pathname.startsWith('/jysk');
    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/jysk" pathname={location.pathname}>
                        Jysk
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/jysk/promotion"
                        pathname={location.pathname}
                    >
                        Jysk Promotion
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/jysk/everyday-low-price"
                        pathname={location.pathname}
                    >
                        Jysk Everyday Low Price
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/jysk/outlet"
                        pathname={location.pathname}
                    >
                        Jysk Outlet
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const JyskLocation = (props) => (
    <Location>
        {(locationProps) => <Jysk {...locationProps} {...props} />}
    </Location>
);

export default JyskLocation;
