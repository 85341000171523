import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Regatta = ({ location }) => {
    const show = location.pathname.startsWith('/regatta');

    // useEffect(() => {
    //     /*
    //     const el = document.getElementById('out');

    //     console.info(el);
    //     if (el != null) el.scrollIntoView();*/
    // });

    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/regatta" pathname={location.pathname}>
                        Regatta
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/regatta/men-outlet"
                        pathname={location.pathname}
                    >
                        Regatta Men Outlet
                    </MenuItemStyled>
                    <MenuItemStyled
                        id="out"
                        to="/regatta/women-outlet"
                        pathname={location.pathname}
                    >
                        Regatta Women Outlet
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/regatta/kids-outlet"
                        pathname={location.pathname}
                    >
                        Regatta Kids Outlet
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const RegattaLocation = (props) => (
    <Location>
        {(locationProps) => <Regatta {...locationProps} {...props} />}
    </Location>
);

export default RegattaLocation;
