import { Location } from '@reach/router';
import React from 'react';

import MenuItemStyled from '../MenuItemStyled';

const Ikea = ({ location }) => {
    const show = location.pathname.startsWith('/ikea');
    return (
        <>
            {show && (
                <>
                    <MenuItemStyled to="/ikea" pathname={location.pathname}>
                        Ikea
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ikea/highlight"
                        pathname={location.pathname}
                    >
                        Ikea Highlight
                    </MenuItemStyled>
                    <MenuItemStyled
                        to="/ikea/lower-price"
                        pathname={location.pathname}
                    >
                        Ikea Lower Price
                    </MenuItemStyled>
                </>
            )}
        </>
    );
};

const IkeaLocation = (props) => (
    <Location>
        {(locationProps) => <Ikea {...locationProps} {...props} />}
    </Location>
);

export default IkeaLocation;
