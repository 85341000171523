import * as React from 'react';

import CenterHorizontalStyled from './CenterHorizontalStyled';

const Footer = () => {
    return (
        <footer className="footer-module--footerStyle--2smTq">
            <CenterHorizontalStyled className="py-3">
                Copyright Driving Test © All rights reserved
            </CenterHorizontalStyled>
        </footer>
    );
};

export default Footer;
